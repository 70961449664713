<template>
    <div class="card card-in rounded-3 mb-4">
        <div class="card-body p-2">
            <div class="row d-flex justify-content-between align-items-center">
                <div class="col-md-2 col-lg-2 col-xl-2">
                    <img
                        :src="urlImagem"
                        class="img-fluid rounded-3"
                        alt="Cotton T-shirt"
                    />
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3">
                    <p class="lead mb-2">{{ titulo }}</p>
                </div>
                <div class="col-md-3 col-lg-3 col-xl-2 d-flex">
                    <button class="btn btn-link px-2 mx-1" @click="decrementa">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                            />
                        </svg>
                    </button>

                    <input
                        class="form-control form-control-sm text-center"
                        v-model.number="qtdeItem"
                        @keyup="atualizar"
                    />

                    <button class="btn btn-link px-2 mx-1" @click="incrementa">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-plus"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                            />
                        </svg>
                    </button>
                </div>
                <div class="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                    <h5 class="mb-0">{{ valorTotal | formatarMoedaBR }}</h5>
                    <small>{{ preco | formatarMoedaBR }}</small>
                </div>
                <div class="col-md-1 col-lg-1 col-xl-1 text-end">
                    <a
                        href="#!"
                        @click.prevent="removerItem"
                        class="text-danger"
                        ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="currentColor"
                            class="bi bi-trash-fill"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
                            /></svg
                    ></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "CarrinhoItem",
    data() {
        return {
            valorTotal: 0,
            qtdeItem: 0,
        };
    },

    methods: {
        ...mapActions(["removerItemCarrinho", "alterarItemCarrinho"]),

        async removerItem() {
            await this.removerItemCarrinho(this.id);
        },

        incrementa() {
            this.qtdeItem += 1;
            this.atualizarItem(this.qtdeItem);
        },

        decrementa() {
            if (this.qtdeItem > 1) {
                this.qtdeItem -= 1;
                this.atualizarItem(this.qtdeItem);
            }
        },

        calcularSubTotal() {
            this.valorTotal = this.qtdeItem * this.preco;
        },

        async atualizarItem(valor) {
            let dados = { id: this.id, qtde: valor };
            await this.alterarItemCarrinho(dados);
        },

        async atualizar() {
            let dados = { id: this.id, qtde: this.qtdeItem };
            await this.alterarItemCarrinho(dados);
        },
    },

    watch: {
        qtdeItem(valor) {
            if (valor > 99) {
                this.qtdeItem = 99;
            }
            this.calcularSubTotal();
        },
    },

    props: {
        id: Number,
        urlImagem: String,
        titulo: String,
        descricao: String,
        qtde: Number,
        preco: Number,
    },

    mounted() {
        this.qtdeItem = this.qtde;
        this.calcularSubTotal();
    },
};
</script>

<style lang="scss" >
@import "@/assets/scss/style.scss";
.card-in {
    background-color: $branco !important;
    p {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 21px;
        line-height: 26px;
        .text-muted {
            color: $marrom !important;
            font-family: $roboto;
            font-weight: normal;
            font-style: normal;
            font-size: 16px;
            line-height: 16px;
        }
    }
    h5 {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 28px;
        line-height: 36px;
    }
    small {
        color: $marrom !important;
        font-family: $roboto;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
    }
}
@media (max-width: 767.98px) {
    .card-in {
        p {
            padding: 20px 0;            
        }
        h5 {
            padding-top: 20px;
        }
    }
}
</style>
