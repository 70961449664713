<template>
    <div class="carrinho">
        <div class="container">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>Carrinho Itens</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-5">
                <div class="container h-100">
                    <div
                        class="
                            row
                            d-flex
                            justify-content-center
                            align-items-center
                            h-100
                        "
                    >
                        <div class="col">
                            <div class="card" v-if="itensCarrinho.length > 0">
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <carrinho-item
                                                v-for="item in itensCarrinho"
                                                :key="item.id"
                                                :id="item.id"
                                                :titulo="item.titulo"
                                                :qtde="item.qtde"
                                                :preco="item.valor"
                                                :descricao="item.descricao"
                                                :url-imagem="item.url_midia"
                                            />
                                        </div>
                                        <div
                                            class="
                                                col-12
                                                d-flex
                                                justify-content-between
                                            "
                                        >
                                            <button
                                                type="button"
                                                class="
                                                    btn btn-site btn-excluir
                                                    mx-2
                                                "
                                                style="background-color: #fff"
                                                @click="limparCarrinho"
                                            >
                                                Limpar Carrinho
                                            </button>

                                            <h2 class="my-2 text-end">
                                                Valor Total:
                                                {{
                                                    valorTotal | formatarMoedaBR
                                                }}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="card mt-4"
                                v-if="itensCarrinho.length > 0"
                            >
                                <div
                                    class="card-body d-flex justify-content-end"
                                >
                                    <router-link
                                        class="btn btn-site mx-2"
                                        :to="{ name: 'ProdutoLista' }"
                                    >
                                        Continuar comprando
                                    </router-link>

                                    <button
                                        type="button"
                                        class="btn btn-site mx-2"
                                        style="background-color: #fff"
                                        @click="irCadastro"
                                    >
                                        Ir para o cadastro
                                    </button>
                                </div>
                            </div>
                            <div
                                class="card mt-4"
                                v-if="itensCarrinho.length == 0 && !isLoad"
                            >
                                <div class="card-body text-center">
                                    <h2 class="my-5">
                                        O seu carrinho está vazio.
                                    </h2>
                                    <router-link
                                        class="btn btn-site mx-2 mb-4"
                                        :to="{ name: 'ProdutoLista' }"
                                    >
                                        Continuar comprando
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <loading :active="isLoad" :can-cancel="true" :is-full-page="true" />
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CarrinhoItem from "../components/CarrinhoItem.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    components: { CarrinhoItem, Loading },
    name: "Carrinho",

    computed: {
        ...mapState({
            itensCarrinho: (state) => state.carrinho.itens,
            isLoad: (state) => state.carrinho.loading,
            valorTotal: (state) => state.carrinho.valorTotal,
        }),
    },

    methods: {
        ...mapActions(["carregarCarrinho", "limparCarrinho"]),
        irCadastro() {
            this.$router.push({ name: "Cadastro" });
        },
    },

    async mounted() {
        await this.carregarCarrinho();
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.carrinho {
    @include background;
    .titulo {
        @include titulo;
    }
    .btn-site {
        @include btn-site;
        color: $marrom;
        background-color: $amarelo;
    }
    .btn-excluir {
        font-size: 14px;
        line-height: 14px;
        color: red;
        padding: 7px 14px;
    }
    .card {
        background-color: $marrom;
    }
    h2 {
        color: $branco;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
    }
}
@media (max-width: 767.98px) {
    .carrinho {
        padding: 50px 0 80px;

        .btn-site {
            font-size: 16px;
            line-height: 19px;
        }
    }
}
</style>
